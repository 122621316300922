#list-page {
    .article-list {
        margin: 0;
        padding: 0;

        > li {
            margin: 0 0 1.25rem;

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }

                .published-at {
                    font-weight: bold;
                }
            }
        }
    }
}
