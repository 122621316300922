html {
  font-family: 'Roboto Slab', serif;
  font-weight: normal;
  font-size: 14px;

  @media print {
    font-size: 14px;
  }

  @media screen and (min-width: 300px) {
    font-size: 16px;
  }

  @media screen and (min-width: 600px) {
    font-size: 18px;
  }

  @media screen and (min-width: 900px) {
    font-size: 20px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 22px;
  }
}
