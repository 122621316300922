#home-page {
  $font-color-read-more: #000;
  $background-color-page: #fff;

  @media screen and (min-width: 1600px) {
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
  }

  ul.latest-posts-list {
    list-style: none;
    margin: 0;

    @media screen and (min-width: 1600px) {
      margin: 0 auto;
    }

    li.latest-post {
      list-style: none;
      margin: 0;
      border-bottom: 1px solid #000;
      padding: 3rem 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }

      h2 {
        margin: 1.6rem 0 0;
        text-decoration: none;
      }

      .created-at {
        margin: 0.83rem 0 0;
      }

      .summary {
        margin: 0.83rem 0 0;
        text-align: justify;
      }

      .read-more {
        margin: 0.83rem 0 0;

        a {
          color: $font-color-read-more;
          font-weight: bold;
        }
      }
    }
  }
}
