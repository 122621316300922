footer {
  $font-family-footer: 'Roboto Slab', serif;
  $font-color-footer: #4e4e4e;
  $background-color-footer: #dcd9d4;

  background-color: $background-color-footer;
  border-top: 1px solid #aaa;

  @media print {
    display: none;
  }

  #footer-content {
    padding: 40px 0;

    @media screen and (min-width: 600px) {
      display: flex;
      align-items: center;
    }

    #open_preferences_center {
      margin-top: 20px;

      @media screen and (min-width: 600px) {
        margin-top: 0;
        margin-left: auto;
      }
    }

    a,
    a:hover,
    .divider {
      font-family: $font-family-footer;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1rem;
      letter-spacing: -0.06em;
      color: $font-color-footer;
      text-decoration: none;
      display: block;
      margin-bottom: 40px;

      &:hover {
        text-decoration: underline;
      }

      @media screen and (min-width: 600px) {
        margin: 0;
      }
    }

    .divider {
      display: none;

      @media screen and (min-width: 600px) {
        display: inline;
        margin: 0 20px;
      }
    }
  }
}
