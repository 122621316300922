// base
@import "./_partials/base.scss";
@import "./_partials/shared.scss";

// page layout
@import "./_partials/header.scss";
@import "./_partials/footer.scss";
@import "./_partials/breadcrumbs.scss";
@import "./_partials/search_results.scss";

// page elements
@import "./_partials/headings.scss";
@import "./_partials/article.scss";
@import "./_partials/lists.scss";
@import "./_partials/blockquotes.scss";
@import "./_partials/code.scss";
@import "./_partials/tables.scss";
@import "./_partials/paginator.scss";

// pages
@import "./_partials/home.scss";
@import "./_partials/list-page.scss";
@import "./_partials/single-page.scss";
@import "./_partials/not_found.scss";
