#single-page {
  .pager {
    @media print {
      display: none;
    }

    margin: 5rem 0 0;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 2rem 0 1rem;
    border-top: 1px solid #d9d9d9;

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.1rem;
      color: #2980ba;
      text-decoration: none;
      display: flex;
      align-items: center;

      &:hover {
        text-decoration: underline;
      }
    }

    .prev-section {
      text-align: left;
      margin: 0 auto 0 0;

      .chevron {
        margin: 0 0.4rem 0 0;
      }
    }

    .next-section {
      text-align: right;

      .chevron {
        margin: 0 0 0 0.4rem;
      }
    }
  }
}
